import React from "react";

import Header from "./header";
import { Link } from "gatsby";

const Layout = ({ children }) => {
  return (
    <div className="o-page">
      <Header />
      {children}
      <footer className="o-container u-pv-20">
        <Link to="/privacy-policy" className="c-footer__link u-mr">
          Privacy policy
        </Link>
        <Link to="/terms-and-conditions" className="c-footer__link">
          Terms and conditions
        </Link>
      </footer>
    </div>
  );
};

export default Layout;
