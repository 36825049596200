import React from "react";
import { Link } from "gatsby";
import c from "../images/c.svg";

const Header = () => {
  return (
    <header className="o-container c-header">
      <div className="c-header__logo">
        <Link to="/" className="c-logo">
          <img src={c} alt="whist.live clubs"/>
          whist.<span>live</span>
        </Link>
      </div>
    </header>
  );
};

export default Header;
